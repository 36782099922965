import overlayApi from "solid-overlays";

import ModalSheetLayout from "@repo/ui/overlays/ModalSheetLayout";
import UObject from "@repo/utils/UObject";

const overlayByPath = Object.assign(
  UObject.trimKeys(
    // prettier-ignore
    import.meta.glob("./**/*.tsx") as unknown as {
/* GENERATED */ "./Confirm.tsx": () => Promise<typeof import("./Confirm.tsx")>,
/* GENERATED */ "./info/creditLine/Statements.tsx": () => Promise<typeof import("./info/creditLine/Statements.tsx")>,
/* GENERATED */ "./info/creditLine/Transactions.tsx": () => Promise<typeof import("./info/creditLine/Transactions.tsx")>,
/* GENERATED */ "./manual-idv/FailureReasonPrompt.tsx": () => Promise<typeof import("./manual-idv/FailureReasonPrompt.tsx")>
},
    "./",
    ".tsx",
  ),
  UObject.trimKeys(
    // prettier-ignore
    import.meta.glob("../**/*.overlay.tsx") as unknown as {

},
    "./",
    ".overlay.tsx",
  ),
);

export const {
  OverlaysProvider,
  useOverlay,
  useOverlayBackdrop,
  useOverlayLayout,
  useOverlaysBase,
  useOverlaysController,
} = overlayApi(overlayByPath, {
  DefaultLayout: ModalSheetLayout,
}).create({});
