import c from "class-c";

import styles from "./Key.module.scss";

declare namespace Key {
  type Props = {
    class?: string;
    children: string;
  };
}

function Key({ class: className, children }: D<Key.Props>) {
  return <kbd class={c`${styles.key} ${className}`}>{children}</kbd>;
}

export default Key;
