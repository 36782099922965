import { JSXElement } from "solid-js";
import c from "class-c";

import styles from "./ModalSheet.module.scss";

declare namespace ModalSheet {
  interface Props {
    class?: string;
    children: JSXElement;
  }
}

function ModalSheet({ class: className, children }: D<ModalSheet.Props>) {
  return (
    <div class={c`${styles["hybrid-modal"]} ${className}`}>{children}</div>
  );
}

export default ModalSheet;
