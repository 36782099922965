import c from "class-c";
import { useOverlayBackdrop } from "solid-overlays";
import { reactiveProps } from "solid-u";

import WithKeybind from "../keybind/WithKeybind";

import styles from "./DarkenBackdrop.module.scss";

declare namespace DarkenBackdrop {
  interface Props {
    noClose?: boolean;
  }
}

function DarkenBackdrop(_props: DarkenBackdrop.Props) {
  const { noClose } = reactiveProps(_props);

  const { show, removeCurrent } = useOverlayBackdrop();

  return (
    <WithKeybind key="Escape" noTip>
      <div
        class={c(styles)`darken-backdrop ${{ show }}`}
        onClick={() => {
          if (noClose?.() || !show()) return;
          removeCurrent();
        }}
      />
    </WithKeybind>
  );
}

export default DarkenBackdrop;
