import { createEffect, createSignal, JSX, onMount } from "solid-js";
import c from "class-c";
import { useOverlayLayout } from "solid-overlays";

import DarkenBackdrop from "./DarkenBackdrop";
import ModalSheet from "./ModalSheet";

import styles from "./ModalSheetLayout.module.scss";

declare namespace ModalSheetLayout {
  interface Props {
    children: JSX.Element;
  }
}

function ModalSheetLayout(props: ModalSheetLayout.Props) {
  const [initial, setInitial] = createSignal(true);

  onMount(() => {
    requestAnimationFrame(() => {
      setInitial(false);
    });
  });

  const layout = useOverlayLayout(ModalSheetLayout);

  const prev = () => layout.getRelative(-1);

  const left = () => layout.index() < layout.root.current().index;
  const right = () => layout.index() > layout.root.current().index;
  const enterRight = () =>
    layout.isCurrent() && prev() && initial() && layout.isPresent();
  const cleared = () => layout.root.current().index === -1;

  createEffect(() => {
    if (!layout.isPresent())
      setTimeout(() => {
        layout.safeToRemove();
      }, 400);
  });

  return (
    <div
      class={c(styles)`
        hybrid-layout
        ${{ left }}
        ${(right() || enterRight()) && !cleared() && "right"}
        ${layout.isCurrent() && "current"}
        ${layout.isPresent() && "present"}
      `}
    >
      <ModalSheet>{props.children}</ModalSheet>
    </div>
  );
}

ModalSheetLayout.Backdrop = DarkenBackdrop;

export default ModalSheetLayout;
