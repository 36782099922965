import { createSignal, JSX, onCleanup, onMount } from "solid-js";

import UEventTarget from "@repo/utils-client/UEventTarget";
import USolid from "@repo/utils-solid/USolid";

import FloatingWithAnchor from "../floating/FloatingWithAnchor";
import createDomRect from "../signals/createDomRect";

import Key from "./Key";

declare namespace WithKeybind {
  type Props = {
    alt?: boolean;
    key: string;
    noTip?: boolean;
    children?: JSX.Element;
  };
}

function WithKeybind({
  alt,
  key,
  noTip,
  children: _children,
}: D<WithKeybind.Props>) {
  const children = USolid.children(() => _children);

  const [show, setShow] = createSignal(false);
  const rect = createDomRect({ disabled: () => !show() });

  onMount(() => {
    const child = children();
    if (!(child instanceof HTMLElement))
      throw new Error("WithKeybind must have 1 child element.");

    rect.ref(child);
    if (!alt) setShow(true);

    const events = UEventTarget.wrap(window);

    onCleanup(
      events.on("keydown", (e) => {
        if (e.key === "Alt") setShow(true);
        if (
          (!alt || e.altKey) &&
          e.code === (key.length === 1 ? `Key${key}` : key)
        )
          child.click();
      }).off,
    );
    onCleanup(
      events.on("keyup", (e) => {
        if (alt && e.key === "Alt") setShow(false);
      }).off,
    );
  });

  return (
    <FloatingWithAnchor anchor={children()} show={show()}>
      {!noTip && (
        <div class="text-center mt-0.5">
          <Key class="text:small+">{`${alt ? "Alt+" : ""}${key}`}</Key>
        </div>
      )}
    </FloatingWithAnchor>
  );
}

export default WithKeybind;
